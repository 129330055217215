import { AlpineComponent } from 'alpinejs'
import Swiper from 'swiper'
import { Navigation, Thumbs } from 'swiper/modules'
import 'swiper/css/bundle'
import 'swiper/css/pagination'
import type { SwiperOptions } from 'swiper/types/swiper-options'

export function Timeline(): AlpineComponent {
  return {
    mainSwiper: null,
    thumbSwiper: null,

    init() {
      this.thumbSwiper = new Swiper(this.$refs.slider2, {
        spaceBetween: 25,
        freeMode: true,
        watchSlidesProgress: true,
        slidesPerView: 'auto',
      })

      this.mainSwiper = new Swiper(this.$refs.slider, {
        modules: [Navigation, Thumbs],
        loop: false,
        spaceBetween: 30,
        autoHeight: true,
        navigation: {
          nextEl: this.$refs.buttonNext,
          prevEl: this.$refs.buttonPrev,
        },
        thumbs: {
          swiper: this.thumbSwiper,
          slideThumbActiveClass: 'text-red-900',
        },
      })
    },

    discard() {
      this.mainSwiper.destroy()
      this.thumbSwiper.destroy()
    },
  }
}
