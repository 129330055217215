import fetchJsonp from 'fetch-jsonp'
import serialize from 'form-serialize'
import Cookies from 'js-cookie'
import { SHOP_URL } from '@js/lib/constants'

// Example Use:
// <div x-data="addtocart('SKU')" x-bind="submit" class=""></div>

export default (sku = null) => ({
  form: '',
  sku: sku,
  shopDomain: SHOP_URL,
  selectedId: null,

  select(id, from) {
    this.selectedId = id
  },

  isSelected(id) {
    return this.selectedId === id
  },

  whichChild(el, parent) {
    return Array.from(parent.children).indexOf(el) + 1
  },

  whichChild(el, parent) {
    return Array.from(parent.children).indexOf(el) + 1
  },

  submit: {
    ['@submit.prevent.stop'](ev) {
      this.processAddToCart(ev)
    },
  },

  async init() {
    if (!this.sku) {
      return false
    }

    const url = new URL(`${this.shopDomain}/index.cfm`)
    url.searchParams.append('method', 'remote.addToCartForm')
    url.searchParams.append('productSKU', this.sku)

    await fetchJsonp(url.href, {
      jsonpCallback: '?callback',
      timeout: 7000,
    })
      .then((response) => response.json())
      .then((html) => {
        let wdWrapper = document.createElement('div')
        wdWrapper.innerHTML = html.trim()

        const forms = wdWrapper.querySelectorAll('form')
        const baseId = this.$id('tabs')

        if (forms.length > 1) {
          let tabWrapper = document.createElement('section')
          tabWrapper.setAttribute('x-id', "['tab']")
          tabWrapper.classList.add(
            '@sm:flex-row',
            'flex',
            'items-center',
            'justify-between',
            'flex-col'
          )

          let tabsEl = document.createElement('ul')
          tabsEl.setAttribute('x-ref', 'tablist')
          tabsEl.classList.add(
            'flex',
            'flex-row',
            'gap-x-4',
            'gap-y-1.5',
            'mb-2',
            '@sm:flex-col',
            '@sm:mb-0'
          )
          tabsEl.setAttribute(
            'x-on:keydown.right.prevent.stop',
            '$focus.wrap().next()'
          )
          tabsEl.setAttribute(
            'x-on:keydown.home.prevent.stop',
            '$focus.first()'
          )
          tabsEl.setAttribute(
            'x-on:keydown.page-up.prevent.stop',
            '$focus.first()'
          )
          tabsEl.setAttribute(
            'x-on:keydown.left.prevent.stop',
            '$focus.wrap().prev()'
          )
          tabsEl.setAttribute('x-on:keydown.end.prevent.stop', '$focus.last()')
          tabsEl.setAttribute(
            'x-on:keydown.page-down.prevent.stop',
            '$focus.last()'
          )
          tabsEl.setAttribute('role', 'tablist')

          let caseCopy = wdWrapper.querySelector(
            '.v65-widgetProduct-addToCart-caseQuantity'
          ).innerText
          caseCopy = caseCopy.replace(/\/\s/g, '')

          let tabs = `
            <li>
              <button
                class="text-left w-full font-alt font-semibold text-sm tracking-wider uppercase whitespace-nowrap underline decoration-2 underline-offset-4"
                x-on:click="select($el.id)"
                x-bind:id="'${baseId}-1'"
                x-on:focus="select($el.id)"
                type="button"

                x-bind:aria-selected="isSelected($el.id)"
                x-bind:class="isSelected($el.id) ? '' : 'decoration-transparent'"
                role="tab">
                  Bottle
              </button>
            </li>
            <li>
              <button
                class="text-left w-full font-alt font-semibold text-sm tracking-wider uppercase whitespace-nowrap underline decoration-2 underline-offset-4"
                x-on:click="select($el.id)"
                x-bind:id="'${baseId}-2'"
                x-on:focus="select($el.id)"
                type="button"

                x-bind:aria-selected="isSelected($el.id)"
                x-bind:class="isSelected($el.id) ? '' : 'decoration-transparent'"
                role="tab">
                  ${caseCopy}
              </button>
            </li>`

          forms.forEach((form, i) => {
            if (i === 0) {
              form.style.display = 'none'
              this.select(`${baseId}-${i + 1}`)
            }
            form.setAttribute('x-show', `isSelected('${baseId}-${i + 1}')`)
            form.setAttribute('x-bind:aria-labelledby', `'${baseId}-${i + 1}'`)
            form.setAttribute('role', 'tabpanel')
          })

          tabsEl.innerHTML = tabs.trim()
          tabWrapper.appendChild(tabsEl)
          tabWrapper.appendChild(wdWrapper.firstChild)

          this.form = tabWrapper

          return
        }

        this.form = wdWrapper
      })

    this.$el.innerHTML = this.form.outerHTML
  },

  processAddToCart(ev) {
    const form = ev.target
    const formAction = form.action.split('?method=')[1]

    let serializedForm = serialize(form, { empty: true, hash: false })
    let d = new Date()

    const url = new URL(`${this.shopDomain}/index.cfm`)
    url.searchParams.append('method', formAction)
    url.searchParams.append('modalLayout', 1)
    url.searchParams.append('timeStamp', d.getTime())
    url.searchParams.append('remoteOrderID', this.getOrderID())
    url.searchParams.append('thirdPartyCookiesSupported', false)

    fetchJsonp(`${url.href}&${serializedForm}`, {
      jsonpCallback: '?callback',
    })
      .then((response) => response.json())
      .then((resp) => {
        // Wine direct can return json or html, depending on the thirdPartyCookiesSupported param
        try {
          const json = JSON.parse(resp)
          this.$dispatch('cart-item-added', json)
        } catch (err) {
          // This "error" is really just that resp is not JSON but html
          this.$dispatch('cart-item-added', {
            content: resp,
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })

    if (
      typeof window.dataLayer !== 'undefined' &&
      typeof window.dataLayer.push !== 'undefined' &&
      this.winedata
    ) {
      let formObj = serialize(form[0], { hash: true })

      // add quantity to wine data
      this.winedata.ecommerce.add.products[0].quantity = formObj.Quantity
      window.dataLayer.push(this.winedata)
    }
  },

  getOrderID() {
    if (Cookies.get('ORDERID')) {
      return Cookies.get('ORDERID')
    }

    return ''
    // This will look for the order ID in the url
    // const params = new Proxy(new URLSearchParams(window.location.search), {
    //   get: (searchParams, prop) => searchParams.get(prop),
    // });
    // return params.remoteOrderID;
  },
})
