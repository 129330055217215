import { AlpineComponent } from 'alpinejs'
import Swiper from 'swiper'
import { Pagination, Autoplay, Navigation } from 'swiper/modules'
import 'swiper/css/bundle'
import 'swiper/css/pagination'
import type { SwiperOptions } from 'swiper/types/swiper-options'
import merge from 'ts-deepmerge'

export function Slider(config: SwiperOptions): AlpineComponent {
  const defaultConfig: SwiperOptions = {
    modules: [Pagination, Autoplay, Navigation],
    slidesPerView: 1,
    loop: true,
    spaceBetween: 30,
    pagination: {
      el: this.$refs.pagination,
      type: 'bullets',
      clickable: true,
      bulletClass: 'slider-bullet-flat',
      bulletActiveClass: 'active',
    },
  }

  return {
    swiper: null,

    init() {
      this.swiper = new Swiper(this.$refs.slider, merge(defaultConfig, config))
    },

    discard() {
      this.swiper.destroy()
    },
  }
}
