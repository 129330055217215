import fetchJsonp from 'fetch-jsonp'
import serialize from 'form-serialize'
import { SHOP_URL } from '@js/lib/constants'

// Example Use:
// <div x-data="wdform('Form Name')" x-bind="submit"></div>

export default (formName = '', scrollBack = true) => ({
  form: '',
  formName: formName,
  shopDomain: SHOP_URL,
  scrollBack: scrollBack,

  submit: {
    ['@submit.prevent.stop']() {
      const form = this.$el.querySelector('form')
      const serializedForm = serialize(form, { empty: true, hash: false })
      let d = new Date()

      fetchJsonp(
        `${
          this.shopDomain
        }/index.cfm?method=remote.processForm&${serializedForm}'&timeStamp=${d.getTime()}`,
        {
          jsonpCallback: '?callback',
        }
      )
        .then((response) => response.json())
        .then((html) => {
          let el = this.$el.querySelector('.v65-widgetFormWrapper')
          el.innerHTML = html

          if (this.scrollBack) {
            this.$el.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }

          this.$dispatch('form-submitted')
        })
        .catch((err) => {
          // console.log(err);
        })
    },
  },

  async init() {
    if (!this.formName) {
      return false
    }

    await fetchJsonp(
      `${this.shopDomain}/index.cfm?method=remote.form&formName=${this.formName}`,
      {
        jsonpCallback: '?callback',
      }
    )
      .then((response) => response.json())
      .then((html) => (this.form = html))

    this.$el.innerHTML = this.form

    // attach vin65remote to window element
    window.vin65remote = window.vin65remote || {}
    window.vin65remote.form = this
  },
})
