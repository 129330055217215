import fetchJsonp from 'fetch-jsonp'
import { SHOP_URL } from '@js/lib/constants'

// Example:
// <div x-data="wdlogin()" class="v65-widgetLogin-wrapper"></div>

export default () => ({
  html: '',
  shopDomain: SHOP_URL,

  async init() {
    const url = new URL(`${this.shopDomain}/index.cfm`)
    url.searchParams.append('method', 'remote.loginWidget')
    url.searchParams.append('relocateTo', window.location)

    await fetchJsonp(url.href, {
      jsonpCallback: '?callback',
    })
      .then((response) => response.json())
      .then((html) => {
        this.html = html
      })

    this.$el.innerHTML = this.html
  },
})
