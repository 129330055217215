import Cookies from 'js-cookie'

import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
import alpineUi from '@alpinejs/ui'
import { Slider } from '@js/components/slider.ts'
import { Timeline } from '@js/components/timeline.js'
import { GoogleMap } from './components/map.js'

// Youtube video facade
import '@js/components/liteYoutube.ts'
// Vimeo video facade
import '@js/components/liteVimeo.ts'

import './animations.ts'

// Winedirect widgets
import wdForm from '@js/components/wdForm.js'
import wdAddToCart from '@js/components/wdAddToCart.js'
import wdCart from '@js/components/wdCart.js'
import wdLogin from '@js/components/wdLogin'

// Youtube video facade
import '@js/components/liteYoutube.ts'
// Vimeo video facade
import '@js/components/liteVimeo.ts'

// import '@js/rive/logo.ts'

window.Alpine = Alpine
window['cookies'] = Cookies

Alpine.plugin(alpineUi)
Alpine.plugin(intersect)
Alpine.plugin(focus)
Alpine.plugin(collapse)

Alpine.data('addtocart', wdAddToCart)
Alpine.data('wdform', wdForm)
Alpine.data('wdcart', wdCart)
Alpine.data('wdlogin', wdLogin)
Alpine.data('slider', Slider)
Alpine.data('timeline', Timeline)
Alpine.data('map', GoogleMap)

Alpine.start()
