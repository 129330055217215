import { AlpineComponent } from 'alpinejs'

export function GoogleMap(mapOptions: {
  lat: number
  lng: number
  zoom: number
  url: string
}): AlpineComponent {
  const styles = [
    {
      featureType: 'administrative.locality',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#000000',
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#ffffff',
        },
        {
          weight: '0.75',
        },
      ],
    },
    {
      featureType: 'landscape.natural',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#ded7c6',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#ded7c6',
        },
      ],
    },
    {
      featureType: 'poi.business',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.place_of_worship',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.sports_complex',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          lightness: '0',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          lightness: 100,
        },
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'on',
        },
        {
          lightness: '-41',
        },
        {
          color: '#ac9f86',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [
        {
          visibility: 'on',
        },
        {
          lightness: '-99',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'on',
        },
        {
          lightness: '-13',
        },
        {
          color: '#d4cdb3',
        },
      ],
    },
    {
      featureType: 'road.highway.controlled_access',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'on',
        },
        {
          lightness: '60',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'on',
        },
        {
          lightness: 700,
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          color: '#c3a866',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ]

  return {
    init() {
      const element = this.$el as HTMLElement
      const position = {
        lat: mapOptions.lat,
        lng: mapOptions.lng,
      }

      // Initialize and add the map
      let map
      async function initMap(): Promise<void> {
        // The location of Uluru

        // Request needed libraries.
        //@ts-ignore
        const { Map } = (await google.maps.importLibrary(
          'maps'
        )) as google.maps.MapsLibrary
        const { Marker } = (await google.maps.importLibrary(
          'marker'
        )) as google.maps.MarkerLibrary

        // The map, centered at Uluru
        map = new Map(element, {
          zoom: mapOptions.zoom || 4,
          center: position,
          styles: styles,
        })

        // The marker, positioned at Uluru
        const marker = new Marker({
          map: map,
          position: position,
          icon: 'assets/img/site/map-pin.png',
          title: 'Domaine Serene',
        })

        marker.addListener('click', () => {
          window.open(mapOptions.url, '_blank')
        })
      }
      initMap()
    },

    discard() {},
  }
}
